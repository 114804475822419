/* General resets for consistency */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  height: 100%;
  font-family: 'Courier New', Courier, monospace;
  /* background-color: #f0f0f0; /* Light gray background for daylight readability */
  background-size: 200% 200%;
  /* background-image: #c30101; */
  animation: gradientAnimation 60s ease infinite;
  color: #333; /* Dark text for contrast */
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Adjust to align items from the start */
}
.App.light {
  background-color: #fff;
  color: #000;
}

.App.dark {
  background-color: #1e1e2f; /* Dark navy/indigo for rich contrast */
  color: #f0f0f0; /* Soft white text for readability */
}

/* Styling for the navigation bar */
.App-nav {
  width: 100%;
  background-color: #fff; /* Light background for the nav bar */
  padding: 1rem 2rem; /* Increase padding for better spacing */
  display: flex;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Lighter shadow for subtlety */
  position: sticky;
  top: 0;
  z-index: 10; /* Ensure nav stays on top */
}
.dark .App-nav {
  background-color: #2c2c3e; /* Darker background for nav */
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}


.App-nav a {
  color: #3a00c2; /* A modern blue for links */
  text-decoration: none;
  margin: 0 1rem;
  transition: color 0.3s ease;
}

.App-nav a:hover,
.App-nav a:focus {
  color: #ff2a2a; /* Darker blue on hover/focus */
}

.dark .App-nav a {
  color: #ffdcac; /* Warm yellow links for dark theme */
}
.dark .App-nav a:hover,
.dark .App-nav a:focus {
  color: #ffca3a; /* Bright yellow on hover in dark mode */
}


/* Styles for the welcome message */
.welcome-message {
  color: #3a00c2; /* Dark color for readability */
  margin-left: auto; /* This ensures it stays to the right */
  display: flex;
  align-items: center; /* Center message vertically */
}

.dark .welcome-message {
  color: #f0a500; /* Warm yellow for dark theme */
}


/* Header adjustments */
.App-header {
  text-align: center;
  margin-top: 20px;
}

.App-header p {
  color: #3d0091;
  margin-top: 10px;
  font-size: 1.2rem;
  opacity: 0.85;
}

.App-header p2 {
  color: #660505;
  margin-top: 10px;
  font-size: 1.2rem;
  opacity: 0.85;
}

.App-header h1 {
  color: #0f0897; /* Modern blue for a pop of color */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
  font-size: 4rem; /* Larger size for emphasis */
}

/* Link and Sub-nav adjustments for day mode */
.Link {
  color: #007bff;
  font-size: 1.2rem; /* Larger size for emphasis */
  text-decoration: none;
  margin: 0 2rem;
  transition: color 0.3s ease;
}

.Link:hover, .Link:focus {
  color: #0056b3;
}

.dark .Link {
  color: #f0a500;
}

.dark .Link:hover, .dark .Link:focus {
  color: #ffca3a;
}

.Sub-nav .Link {
  background-color: #fff4f4;
  color: #0052aa;
  border: 2px solid #0052aa;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.Sub-nav .Link:hover {
  background-color: #0f0897cc;
  color: #ffffff;
}

.dark .Sub-nav .Link {
  background-color: #3a3a52;
  color: #f0a500;
  border: 2px solid #f0a500;
}.dark .Sub-nav .Link:hover {
  background-color: #ffca3a;
  color: #1e1e2f;
}
/* Sub-navigation Styling */
.Sub-nav {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
}

.Sub-nav button {
  background-color: transparent;
  color: #333;
  border: 2px solid #007bff; /* Modern blue border */
  margin: 0 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;
  transition:d background-color 0.2s, color 0.2s;
}

.dark .Sub-nav button {
  color: #f0a500;
  border: 2px solid #f0a500;
}

.Sub-nav button:hover {
  background-color: #007bff; /* Blue fill on hover */
  color: #ffffff; /* White text for contrast */
}
/* Adjustments for smaller screens and responsiveness */
@media (max-width: 768px) {
  .App-nav {
    flex-direction: column;
    padding: 1rem;
  }

  .App-nav a {
    margin: 5px 0; /* Stack links with space in between */
  }

  .Sub-nav {
    flex-wrap: wrap; /* Wrap buttons for smaller screens */
  }
}