:root {
    --primary-color: #0e1264; /* A vibrant green */
    --background-color: #f0f0f0; /* A soft gray for the background */
    --done-background-color: #bbb; /* A slightly darker gray for completed tasks */
    --text-color: #333; /* Dark gray for text */
    --done-text-color: #999; /* Lighter gray for completed task text */
    --border-radius: 8px; /* Increased border radius for rounded corners */
    --transition-speed: 0.3s; /* Smooth transition speed for interactive elements */
    --shadow-color: rgba(0, 0, 0, 0.2); /* Shadow color for depth */
    --input-background: #e0e0e0; /* Lighter background for input for contrast */
}

.container {
    max-width: 3000px;
    margin: 0 auto;
    padding: 0 20px;
}

body {
    margin: 0;  
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
}

.taskListHeader, .taskItem {
    display: flex;
    justify-content: space-between; /* This ensures that items are spaced evenly */
    padding: 15px 20px;
    background-color: var(--primary-color);
    border-radius: var(--border-radius);
    margin: 10px 0;
    box-shadow: 0 2px 4px var(--shadow-color);
}

.taskListHeader {
    background-color: var(--primary-color);
    color: white;
}

.taskItemDetail, .taskListHeaderItem {
    flex: 1; /* This gives each detail item and header item equal space */
    text-align: center; /* Centers the text within each section */
}

/* Adjustments for the checkbox alignment */
.taskItemCheckbox {
    flex: 0.2; /* Assign less space for checkbox */
    text-align: left; /* Align checkbox to the left */
}

.taskItemDetail {
    flex: 1; /* Adjusts the flex-grow to ensure it takes the available space equally */
    text-align: center; /* Center aligns the text for detail items */
}

/* Assuming you want the 'Task' detail to have more space */
.taskItemDetail.taskName {
    flex: 2; /* Gives double space to the task name detail for better readability */
}
.taskListHeaderItem {
    text-align: center;
    font-weight: 600;
}

.taskList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.taskItem {
    background-color: var(--background-color);
    transition: box-shadow var(--transition-speed), transform var(--transition-speed);
}

.taskItem:hover {
    background-color: #e9e9e9;
    box-shadow: 0 5px 15px var(--shadow-color);
    transform: translateY(-2px); /* Slightly raise the task item on hover */
}

.taskItemDone {
    background-color: var(--done-background-color);
    color: var(--done-text-color);
    text-decoration: line-through;
}

.input, .addButton {
    padding: 10px;
    border: none;
    border-radius: var(--border-radius);
    transition: background-color var(--transition-speed), box-shadow var(--transition-speed);
}

.input {
    background-color: var(--input-background);
    box-shadow: inset 3px 3px 5px var(--shadow-color);
    width: 700px;
    margin-right: 10px;
    flex-grow: 1;
    margin-bottom: 40px;
}

.input:focus {
    outline: none;
    background-color: white;
    box-shadow: inset 2px 2px 5px var(--shadow-color), 0 0 5px var(--primary-color); /* Highlight focus */
}

.addButton {
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    box-shadow: 3px 3px 5px var(--shadow-color);
    transition: background-color var(--transition-speed), transform var(--transition-speed);
}

.addButton:hover {
    background-color: #3f0860; /* Slightly darker green on hover */
    transform: translateY(-2px); /* Raise the button slightly on hover */
    box-shadow: 0 5px 15px var(--shadow-color);
}
